<template>
  <el-row v-if="dataInfo">
    <el-col>
      <el-form label-width="120px">
        <el-form-item label="管控周期类型">
          <el-radio-group v-model="dataInfo.triggerType" @change="changeTriggerType">
            <el-radio-button label="cycle">间隔周期</el-radio-button>
            <el-radio-button label="fixed">固定日期</el-radio-button>
            <el-radio-button label="day">每日</el-radio-button>
            <el-radio-button label="week">每周</el-radio-button>
            <el-radio-button label="month">每月</el-radio-button>
            <el-radio-button label="year">每年</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="dataInfo.triggerType != 'fixed'" label="管控生效有效期" prop="startDate">
          <l-datepicker type="date" v-model="dataInfo.startDate" style="width:130px;" @change="changeDate"></l-datepicker>
          至
          <l-datepicker type="date" v-model="dataInfo.endDate" style="width:130px;" @change="changeDate"></l-datepicker>
          <span class="padding-left-sm text-cyan text-ls">不填写,默认从今日开始,截止日期不限</span>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <trigger-cycle v-if="dataInfo.triggerType === 'cycle'" :dataInfo="dataInfo" @refresh="refreshTrigger"></trigger-cycle>
      <trigger-fixed v-else-if="dataInfo.triggerType === 'fixed'" :dataInfo="dataInfo" @refresh="refreshTrigger"></trigger-fixed>
      <trigger-day v-else-if="dataInfo.triggerType === 'day'" :dataInfo="dataInfo" @refresh="refreshTrigger"></trigger-day>
      <trigger-week v-else-if="dataInfo.triggerType === 'week'" :dataInfo="dataInfo" @refresh="refreshTrigger"></trigger-week>
      <trigger-month v-else-if="dataInfo.triggerType === 'month'" :dataInfo="dataInfo" @refresh="refreshTrigger"></trigger-month>
      <trigger-year v-else-if="dataInfo.triggerType === 'year'" :dataInfo="dataInfo" @refresh="refreshTrigger"></trigger-year>
      <el-divider></el-divider>
      <el-form label-width="120px">
        <el-form-item label="管控周期说明" style="margin-bottom:5px;">
          <p>
            <span class="text-bold margin-right-mini">{{ dataInfo.triggerName }}:</span>{{ dataInfo.triggerDesc }}
          </p>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col v-if="dataInfo.planList">
      <el-divider></el-divider>
      <l-table :option="tableOption" :data="dataInfo.planList"> </l-table>
    </el-col>
  </el-row>
</template>

<script>
import triggerYear from './trigger_year.vue'
import triggerMonth from './trigger_month.vue'
import triggerDay from './trigger_day.vue'
import triggerWeek from './trigger_week.vue'
import triggerCycle from './trigger_cycle.vue'
import triggerFixed from './trigger_fixed.vue'

export default {
  components: {
    triggerYear,
    triggerMonth,
    triggerDay,
    triggerWeek,
    triggerCycle,
    triggerFixed
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataInfo: null,
      planList: null,
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '任务创建时间', prop: 'createTime' },
          { label: '任务开始时间', prop: 'startTime' },
          { label: '任务结束时间', prop: 'endTime' }
        ]
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.dataInfo = this.data
      if (!this.dataInfo) {
        this.dataInfo = { triggerType: 'cycle', startDate: null, endDate: null }
      }
      this.refreshTrigger({ data: this.dataInfo, reset: false })
    },
    changeDate() {
      this.refreshTrigger({ data: this.dataInfo, reset: false })
    },
    changeTriggerType() {
      this.dataInfo.subType = null
      this.refreshTrigger({ data: this.dataInfo, reset: true })
    },
    refreshTrigger(data) {
      this.$lgh.post('api/comm/regular/trigger/init?reset=' + data.reset, data.data, { loading: false }).then(res => {
        this.dataInfo = res
        this.$emit('trigger', this.dataInfo)
      })
    }
  }
}
</script>

<style lang="less">
.readonly {
  .el-input__inner {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .el-textarea__inner {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
